import CategoricalScheme from '../../CategoricalScheme';

const schemes = [
  {
    id: 'mts10Colors',
    label: 'MTS_10 Colors',
    colors: [
      '#E30611',
      '#B2251E',
      '#B05B6F',
      '#D48887',
      '#F8D3CB',
      '#E1E3E8',
      '#D0D3D7',
      '#A3A5A8',
      '#80858C',
      '#2D3135',
    ],
  },
  {
    id: 'mts5Colors',
    label: 'MTS_5 Colors',
    colors: ['#B2251E', '#D48887', '#F8D3CB', '#A3A5A8', '#2D3135'],
  },
  {
    id: 'mts3Colors',
    label: 'MTS_3 Colors',
    colors: ['#B2251E', '#B05B6F', '#80858C'],
  },
  {
    id: 'mts2Colors',
    label: 'MTS_2 Colors',
    colors: ['#B2251E', '#80858C'],
  },
].map(s => new CategoricalScheme(s));

export default schemes;
